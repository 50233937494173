import React, {useEffect, useState} from "react";
import API from "../../../utils/API";
import {
    Button,
    Container, FormGroup, Label,
} from "reactstrap";
import {AvField, AvForm, AvInput} from "availity-reactstrap-validation";

export const LandingPage = () => {
    const [products, setProducts] = useState(null);
    const [product, setProduct] = useState(null);
    const [editConfig, setEditConfig] = useState(null);
    const [leadBody, setLeadBody] = useState({});

    const [response, setResponse] = useState(null);

    const [errors, setErrors] = useState(null);

    const handleChange = (e) => {
        setLeadBody({ ...leadBody, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        API.get('/system/products').then((response) => {
            setProducts(response.data.data);
        });
    }, []);

    useEffect(() => {
        setEditConfig(null);
        if (product) {
            API.get(`/testautomation/productfields/${product}`).then((response) => {
                const leadDataTemp = {};
                response.data.data.map((field) => {
                    leadDataTemp[field.name] = field.value;
                });
                setLeadBody(leadDataTemp);
                setEditConfig(response.data.data);
            });
        }
    }, [product]);

    const submit = () => {
        API.post(`/lead/web`,leadBody).then((response) => {
            setProduct(null);
            setLeadBody(null);
            setEditConfig(null);
            setResponse(response.data);
        });
    }

    return <Container fluid className="p-0">
        <h1 className="h3 mb-3">QA Traffic Lead Generator</h1>
        {products &&
        <div className="row">
            {products.map((productItem, index) => {
                return <div className="col" key={index}>
                    <button disabled={productItem.classkey === product} className={`btn ${productItem.classkey === product ? 'btn-secondary' : 'btn-primary'} w-100`} onClick={()=>setProduct(productItem.classkey)}>{productItem.title}</button>
                </div>
            })}
        </div>
        }
        {(!product && response) &&
            <div className="m-3">
                <h3>Response</h3>
                <pre><code>{JSON.stringify(response)}</code></pre>
            </div>
        }
        {editConfig &&
            <div className={'mt-3'}>
                <AvForm onValidSubmit={submit}>
                <div className="text-center">
                    <Button color="primary">Send</Button>
                </div>
                {editConfig.map((field, index) => {
                    if (field.type === "string") {
                        return (
                            <FormGroup key={index}>
                                <Label>{field.name}</Label>
                                <AvInput
                                    className={errors && errors[field.name] && "border-danger"}
                                    onChange={handleChange}
                                    required
                                    type="text"
                                    name={field.name}
                                    value={leadBody[field.name]}
                                    placeholder={"Enter " + field.name}
                                />
                                {errors && errors[field.name] && (
                                    <div className="text-danger font-size-sm">
                                        {errors[field.name]}
                                    </div>
                                )}
                            </FormGroup>
                        );
                    } else if (field.type === "options") {
                        return (
                            <FormGroup key={index}>
                                <Label>{field.name}</Label>
                                <AvField
                                    className={errors && errors[field.name] && "border-danger"}
                                    type="select"
                                    name={field.name}
                                    value={leadBody[field.name]}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select...</option>
                                    {field.options.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </AvField>
                                {errors && errors[field.name] && (
                                    <div className="text-danger font-size-sm">
                                        {errors[field.name]}
                                    </div>
                                )}
                            </FormGroup>
                        );
                    }
                })}
            </AvForm>
        </div>
        }
    </Container>
}
